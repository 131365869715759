import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Box,
  Button,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { CartContext } from "../../context/CartContext";
import PaymentMethods from "./PaymentMethods";
import AddAddressModal from "./AddAddressModal";

const PersonalData = ({
  clientData,
  addresses,
  selectedAddress,
  isNewAddressEnabled,
  deliveryDate,
  setSelectedComuna,
  setSelectedAddress,
  setDeliveryDate,
  paymentMethod,
  handlePaymentMethodChange,
  fetchClientData,
}) => {
  const [availableDates, setAvailableDates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { finalPrice, setShippingAmount } = useContext(CartContext);
  const isMobile = useMediaQuery("(max-width:600px)");

  // Maneja el cambio de dirección
  const handleAddressChange = (event) => {
    const selectedAddressId = event.target.value;
    const selectedAddressObject = addresses.find(
      (address) => address.id === selectedAddressId
    );

    if (selectedAddressObject) {
      setSelectedAddress(selectedAddressObject.id); // Guarda el ID de la dirección
      setSelectedComuna(selectedAddressObject.city.id); // Guarda el ID de la comuna
      setShippingAmount(selectedAddressObject.city.shipping_amount);

      // Actualiza las fechas disponibles
      const deliveryDays = selectedAddressObject.city.deliveryDays || [];
      setAvailableDates(deliveryDays);

      // Si no hay fecha seleccionada, selecciona la primera disponible
      if (!deliveryDate || !deliveryDays.some((day) => day.rawDate === deliveryDate)) {
        if (deliveryDays.length > 0) {
          setDeliveryDate(deliveryDays[0].rawDate);
        } else {
          setDeliveryDate("");
        }
      }
    } else {
      setSelectedAddress(""); // Resetea dirección si no hay seleccionada
      setAvailableDates([]);
      setDeliveryDate(""); // Limpia la fecha seleccionada
    }

    finalPrice();
  };

  // Sincroniza la dirección seleccionada con las fechas disponibles al cargar el componente
  useEffect(() => {
    if (selectedAddress) {
      const selectedAddressObject = addresses.find(
        (address) => address.id === selectedAddress
      );

      if (selectedAddressObject) {
        const deliveryDays = selectedAddressObject.city.deliveryDays || [];
        setAvailableDates(deliveryDays);

        // Conserva la fecha seleccionada o selecciona la primera fecha disponible
        if (!deliveryDate || !deliveryDays.some((day) => day.rawDate === deliveryDate)) {
          if (deliveryDays.length > 0) {
            setDeliveryDate(deliveryDays[0].rawDate);
          } else {
            setDeliveryDate("");
          }
        }
      }
    }
  }, [selectedAddress, addresses, deliveryDate, setDeliveryDate]);

  // Maneja la selección de una fecha de entrega
  const handleDaySelection = (rawDate) => {
    setDeliveryDate(rawDate);
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "auto" }}>
      <Typography variant="h6" gutterBottom>
        Datos Personales
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body1">Nombre:</Typography>
          <Typography variant="body2">
            {`${clientData?.name} ${clientData?.last_name}` || "No disponible"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">RUT:</Typography>
          <Typography variant="body2">
            {clientData?.rut || "No disponible"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Teléfono:</Typography>
          <Typography variant="body2">
            {clientData?.phone || "No disponible"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Correo:</Typography>
          <Typography variant="body2">
            {clientData?.email || "No disponible"}
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
        Dirección de Envío
      </Typography>
      <Select
        value={selectedAddress}
        onChange={handleAddressChange}
        displayEmpty
        fullWidth
        variant="outlined"
        sx={{ marginBottom: 2 }}
        disabled={isNewAddressEnabled}
      >
        <MenuItem value="">
          <em>Selecciona una dirección</em>
        </MenuItem>
        {addresses.map((address) => (
          <MenuItem key={address.id} value={address.id}>
            {address.address} {address.number} - {address.city.name}
          </MenuItem>
        ))}
      </Select>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <IconButton
          color="success"
          onClick={() => setIsModalOpen(true)}
          size="small"
          sx={{ marginRight: 1 }}
        >
          <AddCircleOutlineIcon fontSize="small" />
        </IconButton>
        <Typography
          variant="body2"
          color="success.main"
          sx={{ cursor: "pointer" }}
          onClick={() => setIsModalOpen(true)}
        >
          Agregar nueva dirección
        </Typography>
      </Box>
      <Typography variant="h6" gutterBottom>
        Fechas de Entrega Disponibles
      </Typography>
      <Box display="flex" gap={2} mb={2}>
        {availableDates.length > 0 ? (
          availableDates.map((date, index) => (
            <Button
              key={index}
              variant={deliveryDate === date.rawDate ? "contained" : "outlined"}
              onClick={() => handleDaySelection(date.rawDate)}
            >
              {date.formattedDate}
            </Button>
          ))
        ) : (
          // <Typography variant="body2" color="textSecondary">
          //   Selecciona una dirección para ver las fechas disponibles.
          // </Typography>
          <Typography variant="body2" color="textSecondary">
          Próxima fechas de envio desde el mes de Marzo.
        </Typography>
        )}
      </Box>
       <PaymentMethods
        paymentMethod={paymentMethod}
        handlePaymentMethodChange={handlePaymentMethodChange}
      />
       <AddAddressModal
        client={clientData}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        fetchClientData={fetchClientData}
      /> 
    </Box>
  );
};

export default PersonalData;
