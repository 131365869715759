import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';

const RegisterDialogs = ({
  errorDialogOpen,
  onCloseErrorDialog,
  errorMessage,
  successDialogOpen,
  onCloseSuccessDialog,
  successMessage,
  onSuccessAction
}) => {
  return (
    <>
      {/* Error Dialog */}
      <Dialog
        open={errorDialogOpen}
        onClose={onCloseErrorDialog}
        aria-labelledby="error-dialog-title"
      >
        <DialogTitle id="error-dialog-title">⚠️ Error</DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseErrorDialog} sx={{ color: 'red' }}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        open={successDialogOpen}
        onClose={onCloseSuccessDialog}
        aria-labelledby="success-dialog-title"
      >
        <DialogTitle id="success-dialog-title">
          <Box display="flex" alignItems="center">
            <CheckCircleIcon sx={{ color: green[500], mr: 1 }} />
            Registro exitoso
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {successMessage || "¡Registro exitoso! Ya puedes iniciar sesión."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              onCloseSuccessDialog(); // Cierra el diálogo
              if (onSuccessAction) onSuccessAction(); // Ejecuta la acción adicional si existe
            }} 
            sx={{
              backgroundColor: green[500],
              color: "white",
              "&:hover": { backgroundColor: green[700] }
            }}
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegisterDialogs;
