import React, { useState } from "react";
import { Container, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import StarIcon from "@mui/icons-material/Star";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import EditIcon from "@mui/icons-material/Edit";
import BusinessIcon from "@mui/icons-material/Business";
import PaymentIcon from "@mui/icons-material/Payment";
import ContactMailIcon from "@mui/icons-material/ContactMail";

const categories = [
  { name: "TODO SOBRE T’AKAY", icon: <HelpOutlineIcon /> },
  { name: "TIPOS DE PRODUCTOS, MEMBRESÍA Y PEDIDOS", icon: <StarIcon /> },
  { name: "DESPACHO Y COBERTURAS", icon: <LocalShippingIcon /> },
  { name: "PROBLEMAS CON EL PEDIDO", icon: <ShoppingCartIcon /> },
  { name: "CONTÁCTANOS", icon: <ContactMailIcon /> },
];

const questionsData = {
  "TODO SOBRE T’AKAY": [
    { question: "¿Cómo me registro en T’AKAY?", answer: "Para registrarte, debes dirigirte a la página principal de www.takayapp.cl y eleccionar el botón de color verde “REGISTRARSE”, completar tus datos, aceptar los términos y condiciones y crear la cuenta. Esta cuenta solo puede ser creada y gestionada por un mayor de edad." },
    { question: "¿Cómo inicio sesión?", answer: "Para iniciar sesión, debes dirigirte a la esquina superior derecha y seleccionar el botón amarillo “INGRESAR”, completa tu correo electrónico y contraseña." },
    { question: "¿Qué pasa si olvido mi contraseña?", answer: "Si olvidaste tu contraseña, debes dirigirte a la esquina superior derecha y seleccionar el botón amarillo “INGRESAR”, ahora solo debes presionar el enlace “¿Olvidaste tu contraseña” ?, escribir tu correo electrónico y seguir las instrucciones." },
    { question: "¿En qué consiste una membresía T’AKAY?", answer: "Al ingresar a nuestra página puedes observar que tenemos dos precios por cada producto, un precio normal (valor de mercado) y un precio membresía, el cuál es significativamente menor. Al obtener una membresía T’AKAY podrás comprar a precio preferencial, teniendo un gran ahorro con relación al precio normal." },
    { question: "¿Qué información está disponible en mi cuenta y cómo puedo acceder a ella?", answer: `Una vez en tu cuenta, puedes ver tu información apretando sobre el <strong>botón negro de la esquina superior derecha</strong> donde aparece tu nombre, luego presiona sobre <strong>“Mi cuenta”</strong> y ahí podrás ver tus:<br /><br />
    • <strong>Datos personales</strong><br />
    • <strong>Pedidos y el detalle de cada uno</strong><br />
    • <strong>Direcciones</strong><br />
    • <strong>Saldo de tu membresía</strong><br />
    • <strong>Saldos</strong>` },
    { question: "¿Cómo modifico los datos de mi cuenta?", answer: `Para modificar tus datos debes iniciar sesión y dirigirte a “Mi cuenta” al presionar sobre tu nombre que está de color negro en la esquina superior derecha donde puedes hacer cambios en los siguientes datos: <br /><br />
    <strong> • Datos Personales:</strong> Puedes modificar todos los datos exceptuando tu rut y luego presionar sobre el botón “Guardar Cambios”.<br /><br />
    <strong> • Contraseña:</strong> En la misma pestaña de “Datos personales”, puedes apretar sobre el botón “Cambiar Contraseña”, ingresar tu nueva contraseña y guardar los cambios.<br /><br />
    <strong> • Direcciones:</strong> Presiona sobre el botón “editar”, modifica los datos solicitados y guarda los cambios.` },
  ],
  "TIPOS DE PRODUCTOS, MEMBRESÍA Y PEDIDOS": [
    { question: "¿Qué significan los productos con etiqueta verde?", answer: `En T’AKAY contamos con productos con etiqueta verde, los cuales pueden ser: <br /><br />
    <strong>• Agroecológico: </strong>Productos con un enfoque agrícola que integra prácticas sostenibles y ecológicas para producir alimentos de manera que respete el medio ambiente y promueva la biodiversidad.<br /><br />
    <strong>• Artesanal: </strong>Productos elaborados de manera manual o semi-industrial, utilizando métodos tradicionales y materias primas de calidad, lo que les otorga características únicas de sabor y textura.<br /><br />
    <strong>• Campo: </strong>Productos cosechados el mismo día de su despacho, provenientes de productores locales, garantizando alta calidad y frescura gracias a su proximidad y rápido proceso de recolección. Cultivo tradicional.<br /><br />
    <strong>• Hidropónico: </strong>Productos con un método de cultivo que utiliza soluciones nutritivas en agua para cultivar plantas sin suelo, permitiendo un crecimiento eficiente y controlado en entornos diversos. <br /><br />
    <strong>• Orgánico: </strong>Productos agrícolas cultivados sin pesticidas, fertilizantes sintéticos ni organismos genéticamente modificados (GMO), promoviendo prácticas naturales y sostenibles.<br /><br />
    <strong>• Biodinámico: </strong>Considera la granja cómo un organismo vivo, promoviendo la diversidad y el uso de preparados naturales. Además, se basa en ciclos astronómicos para guiar las prácticas agrícolas, buscando un equilibrio entre el cultivo y el medio ambiente.<br /><br />
    Todos los productos con etiqueta verde, tienen un despacho exclusivo los días sábados, debido a que son cosechados y recolectados durante esa misma mañana.` },
    { question: "¿Cuáles son los tipos de membresía T’AKAY y qué valor tienen?", answer: `Nuestras membresías están pensadas para cada tipo de cliente, por lo que
    contamos con tres tipos de membresías, Semilla, Brote y Cosecha, las que pueden
    tener una vigencia de un mes o un año desde la fecha de suscripción, según sea tu
    elección al momento de adquirirla.
    Actualmente el medio de pago para cada una es a través de Webpay o transferencia
    bancaria y su vigencia comienza en el momento que se confirma el pago.` },
    { question: "Suscripción Mensual", answer: `Tienen una vigencia de 30 días y se divide en las siguientes categorías: <br /><br />
    <strong>• Esencial: </strong> Tiene un valor de $7.000 pesos con un tope de compra de $50.000 pesos a precio membresía.<br /><br />
    <strong>• Premium: </strong>Tiene un valor de $12.000 pesos con un tope de compra de $100.000 pesos a precio membresía.<br /><br />
    <strong>• Plus: </strong>Tiene un valor de $15.000 pesos con un tope de compra de $150.000 pesos a precio membresía.<br /><br />` },
    { question: "Suscripción Anual", answer: `Tienen una vigencia de un año y se divide en las siguientes categorías: <br /><br />
    <strong>• Esencial: </strong> Tiene un valor de $70.000 pesos con un tope de compra de $600.000 pesos a precio membresía.<br /><br />
    <strong>• Premium: </strong> Tiene un valor de $120.000 pesos con un tope de compra de $1.200.000 pesos a precio membresía.<br /><br />
    <strong>• Plus: </strong> Tiene un valor de $150.000 pesos con un tope de compra de $1.800.000 pesos a precio membresía.<br /><br />` },
    { question: "¿Cómo obtener una membresía?", answer: `Para obtener una membresía debes dirigirte a la página principal apretando sobre
    el botón “T’AKAY” y seleccionar el botón verde “Membresía”, para ello antes debes
    estar registrado y haber iniciado sesión. A continuación, selecciona la membresía
    de tu preferencia: Semilla, Brote o Cosecha y la vigencia de ésta, la que puede ser
    mensual o anual y finalmente realiza la compra. <br /><br />
    Podrás ver el saldo de tu membresía: <br /><br />
    <strong>• Webpay: </strong> Después de confirmar el pago debes actualizar la página y podrás observar tu nuevo saldo de membresía.<br /><br />
    <strong>• Transferencia: </strong> Deberás enviar el comprobante de pago a pedidos@takayapp.cl indicando el número de tu membresía. Si realizas la
    compra en horario hábil, se activará en cuanto confirmemos el pago, de lo contrario, se activará al siguiente día hábil. Debes estar atento a tu correo
    porque te llegará un correo como confirmación del pago.<br /><br />` },
    { question: "¿Cómo realizo un pedido en T’AKAY?", answer: `Para realizar compras en T’AKAY debes ingresar a nuestra página www.takayapp.cl,
    registrarte e ingresar a tu sesión. Una vez en tu cuenta puedes ir agregando productos por categoría.<br /><br />
    Si tienes una membresía activa, se agregarán los productos a tu carrito de compras a precio membresía y puedes ir viendo el ahorro que tienes hasta el momento de
    los productos agregados.<br /><br /> Si no tienes una membresía activa, al agregar tu primer producto te preguntará si deseas adquirir una, si no lo deseas, podrás ir agregando productos a precio normal
    y en la franja superior podrás ver lo que podrías ahorrar con una membresía.<br /><br />
    Luego debes ir al carrito de compras y presionar sobre “IR A PAGAR”.<br />` },
    { question: "¿Cómo se puede pagar un pedido?", answer: `Para completar tu compra, debes realizar una transferencia bancaria utilizando los
    datos que te proporcionaremos al finalizar el pedido. Recuerda enviar el comprobante para confirmar tu pago a pedidos@takayapp.cl y agilizar el proceso
    de entrega.<br /><br />
    No se acepta dinero en efectivo, ya que nuestros repartidores no están autorizados
    por razones de seguridad.<br />` },
    { question: "¿Qué ocurre si se me acaba el saldo de la membresía antes de que se termine el periodo de vigencia?", answer: `En este caso, puedes volver a adquirir una, la que sea de tu preferencia, la cual
    tendrá una nueva fecha de vigencia a partir de la fecha de confirmación del pago.<br /><br />
    Si estás agregando productos a tu carrito de compras y el saldo de tu membresía se acaba antes de finalizar tu compra, puedes sin problema comprar una
    nuevamente, actualizar tu página y seguir comprando. Al momento de finalizar el pedido, la primera membresía quedará inactiva con saldo $0 y la segunda quedará
    activa con el saldo correspondiente.<br />` },
    { question: "Tengo un cupón. ¿Cómo puedo aplicarlo?", answer: `Luego de seleccionar tu día de despacho y agregar los productos en tu carrito de
    compras, puedes aplicar tus cupones disponibles en el recuadro “Agregar Cupón” y se te realizará el descuento correspondiente..<br />` }
  ],
  "DESPACHO Y COBERTURAS": [
    { question: "¿A qué comunas realizan despachos y cuál es su valor?", answer: `Contamos con cobertura en las siguientes comunas, organizadas por sectores A, B
    y C, con sus respectivos valores de despacho: <br /><br />
    <strong>• Sector A: </strong> <br />
    &emsp;&emsp;&emsp;&emsp;<strong>• Valor de Despacho: </strong>$3.490.<br />
    &emsp;&emsp;&emsp;&emsp;<strong>• Cobertura: </strong><br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• Maipú.<br /><br />
    <strong>• Sector B: </strong> <br />
    &emsp;&emsp;&emsp;&emsp;<strong>• Valor de Despacho: </strong>$2.900.<br />
    &emsp;&emsp;&emsp;&emsp;<strong>• Cobertura: </strong><br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• Cerrillos.<br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• La Florida.<br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• La Reina.<br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• Lo Prado.<br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• Ñuñoa.<br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• Peñalolén.<br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• Providencia.<br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• Pudahuel.<br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• Quinta Normal.<br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• San Miguel.<br /><br />
    <strong>• Sector C: </strong> <br />
    &emsp;&emsp;&emsp;&emsp;<strong>• Valor de Despacho: </strong>$3.490.<br />
    &emsp;&emsp;&emsp;&emsp;<strong>• Cobertura: </strong><br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• Las Condes.<br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• Lo Barnechea.<br />
    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;• Vitacura.<br /><br />
    Puedes revisar si tu comuna está habilitada ingresando tu dirección.` },
    { question: "¿Con cuánto tiempo de anticipación debo hacer un pedido?", answer: `<strong>Pedidos que contengan productos con Etiqueta Verde</strong><br /><br />
    Estos pedidos tienen despacho los días sábados, por lo cual puedes realizar tu pedido hasta las 11:00 am del día viernes anterior. Esto, ya que al ser cosechados
    el mismo día (sábado), debe haber previa coordinación con el campo y sus agricultores. El pago debe ser efectuado hasta las 11:30 hrs. el mismo día viernes
    para finalizar el pedido y asegurar tu entrega.<br /><br />
    En el caso de que hagas antes tu pedido debes realizar tu pago al término de la jornada del día en que hagas tu pedido. Algunos productos de etiqueta verde no
    podrán ser pedidos para los viernes que sean festivos y/o lluevan.<br /><br />
    <strong>Pedidos sin productos con etiqueta verde</strong> <br /><br />
    Estos pedidos tienen despacho los miércoles y sábados, por lo cual, puedes realizar tu pedido hasta las 20:00 hrs. del día anterior a tu fecha de entrega, es decir, martes
    o viernes según corresponda, y tienes hasta las 20:30 hrs. para efectuar el pago de tu pedido y asegurar tu entrega.` },
    { question: "¿Qué días hacen entregas y en qué horario?", answer: `Las entregas se realizan los miércoles y sábados desde las 11:00 hrs. hasta las
    19:00 hrs.` },
    { question: "¿Tenemos despacho para hoy?", answer: `Por el momento, no está disponible, pero forma parte de nuestros planes para el futuro.` },
    { question: "¿Puedo saber en qué horario llegará mi pedido?", answer: `Por el momento no. El horario de despacho es de 11:00 a 19:00 hrs.` },
    { question: "¿Puedo dejar el pedido en la conserjería?", answer: `Sí, muchos de nuestros clientes prefieren que el pedido se deje en la conserjería.
    Si deseas esta opción, te sugerimos informar a tu edificio y mencionarlo en el campo de "observaciones" al realizar tu pedido.` },
  ],
  "PROBLEMAS CON EL PEDIDO": [
    { question: "¿Cómo puedo modificar mi pedido?", answer: `Nuestro principal objetivo en T’AKAY es asegurar la satisfacción del cliente con
    nuestros productos y servicios. Dado el tipo de productos que ofrecemos, es posible que ocasionalmente cometamos errores. No obstante, estamos preparados para
    corregirlos. Puedes enviar un correo a reclamos@takayapp.cl y buscaremos la mejor solución, que podría incluir la reposición del producto o la devolución del
    dinero. Te sugerimos revisar la garantía asociada a nuestro servicio.` },
    { question: "¿Qué pasa si un producto no está disponible el día del despacho?", answer: `En caso de que algún producto no esté disponible, te avisaremos de inmediato. Esto
    puede suceder debido a problemas de calidad, condiciones climáticas, días festivos, o cualquier otro imprevisto que afecte nuestra capacidad de abastecimiento.<br /><br />
    En caso de contar con una alternativa, nos pondremos en contacto contigo para ofrecerte la opción de cambio. Si el producto de reemplazo tiene un precio mayor,
    se generará un saldo a pagar para tu próxima compra que se reflejará de manera automática; si su precio es menor, el monto restante quedará como saldo a favor
    para tu próxima compra y se descontará automáticamente.<br /><br />
    Si no hay un reemplazo disponible, te informaremos que el monto del producto faltante se reflejará como un saldo a favor en tu cuenta, el cual podrás utilizar para
    tu próxima compra, o bien, si lo prefieres, reembolsaremos el monto a la cuenta que nos indiques. Las devoluciones se realizan cada Lunes.` },
    { question: "¿Qué pasa si me doy cuenta de que falta un producto y no me han notificado?", answer: `En este caso es posible que se trate de un producto faltante que, por error, no se
    empacó en tu pedido, para ello te solicitamos enviar un correo a reclamos@takayapp.cl indicando el número de pedido y el producto faltante, responderemos a la brevedad posible ofreciendo dos alternativas como solución, un
    reembolso por el monto del producto o un saldo a favor en tu cuenta T’AKAY el cuál se descontará en tu próxima compra.` },
    { question: "¿Cuál es la política de devolución y garantía?", answer: `Una vez que el pedido sea entregado, tendrás 24 horas para hacer el reclamo, esto
    debido a que la duración y calidad de los productos dependen exclusivamente de
    su almacenamiento y manipulación y T’AKAY no puede comprobar ni asegurar que éstos se estén manejando de forma correcta una vez ingresado al domicilio del
    cliente. Tampoco T’AKAY se puede hacer cargo del manejo de los productos cuando
    estos son entregados en conserjería, será el cliente el responsable de asegurar la mantención adecuada de los productos en estas circunstancias.<br /><br />
    Para que hagas efectiva su garantía, deberás enviar un correo a reclamos@takayapp.cl indicando el número de pedido y adjuntando las imágenes
    necesarias del producto en cuestión (obligatorio). El equipo de T’AKAY se pondrá en contacto contigo y por defecto se abonará el monto a su cuenta T’AKAY que será
    descontado automáticamente en el siguiente pedido, o bien, podrá solicitar devolución del dinero mediante transferencia electrónica. Las devoluciones se
    realizan todos los lunes, si fue solicitada el mismo lunes, se hará al siguiente lunes.<br /><br />
    La devolución o abono correrá para todos aquellos productos que presenten daños en su presentación y que por esta razón no puedan ser consumidos por el cliente. También serán abonados los productos que por el traslado hayan sufrido daños y
    que impidan su consumo.<br /><br />
    Dado que nuestros productos frescos que se venden en la sección, frutas y verduras son productos que varían día a día y no se presentan en las mismas condiciones de sabor, tamaño y calidad todo el tiempo, T’AKAY no puede asegurar gramajes, ni
    sabor, por lo que no se hará devolución o abono en estos casos.<br /><br />
    Debes tener en consideración que nuestros esfuerzos y recursos están enfocados 100% en la calidad de nuestros productos, sin embargo, los productos frescos no
    son estandarizados y no presentan el mismo sabor durante todo el año, a esto se conoce como las temporadas de las frutas y verduras. Por lo tanto, no se harán
    devoluciones por sabor o tamaño de los productos frescos (frutas y verduras).<br /><br />
    Agradecemos a nuestros clientes que igualmente nos hagan saber sus comentarios en nuestros medios de comunicación privada, mensaje privado de Instagram
    (@takayapp.cl), WhatsApp +56 9 7376 5050 o correo electrónico (atencionclientes@takayapp.cl ), porque son nuestra forma de medir el desempeño de nuestro trabajo. 
    Solo así podemos ir mejorando y exigiendo más a nuestros proveedores.` },
    { question: "¿Cómo revisar un saldo pendiente?", answer: `Para revisar el saldo en tu cuenta, inicia sesión en www.takayapp.cl, ingresa a tu
    sesión, presiona sobre el botón negro en la esquina superior derecha donde aparece tu nombre y haz clic en “MI CUENTA”, luego ve a la pestaña "SALDOS” y
    aquí podrás revisar el monto a favor (que se descontará en tu próxima compra) o pendiente de pago (que se agregará en tu próxima compra).` },
  ],
  "CONTÁCTANOS": [
    { question: "CONTÁCTANOS", answer: `Puedes contactarnos a través de nuestro WhatsApp +56 9 7376 5050 o por correo electrónico a contacto@takayapp.cl. <br /><br />
    Nuestro horario de atención es de lunes a viernes, de 09:00 a 17:00 hrs.` },
  ]
};

export const FAQPage = () => {
    const [selectedCategory, setSelectedCategory] = useState("TODO SOBRE T’AKAY");
  
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={3}>
  <Grid item xs={12} md={4}>
    <List>
      {categories.map((category, index) => (
        <ListItem key={index} disablePadding>
          <ListItemButton
            onClick={() => setSelectedCategory(category.name)}
            selected={selectedCategory === category.name}
            sx={{ width: "100%", padding: "12px 16px" }}
          >
            <ListItemIcon>{category.icon}</ListItemIcon>
            <ListItemText primary={category.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </Grid>

  {/* Ajustamos el ancho del contenido de preguntas a md={8} */}
  <Grid item xs={12} md={8}>
    <Typography variant="h5" fontWeight="bold" gutterBottom>
      <HelpOutlineIcon sx={{ mr: 1 }} /> {selectedCategory}
    </Typography>
    {questionsData[selectedCategory]?.map((item, index) => (
      <Accordion key={index}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{item.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography dangerouslySetInnerHTML={{ __html: item.answer }} />
        </AccordionDetails>
      </Accordion>
    ))}
  </Grid>
</Grid>

      </Container>
    );
  };