import React, { useState } from "react";
import { Link } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./HeroSwiper.scss";

import banner1 from "../../assets/swiper/Feria-sin-logo.png";
import ban from "../../assets/swiper/Acelgas-sin-logo.png";
import tomate from "../../assets/swiper/Tomate-logo.png";
import tierra from "../../assets/swiper/tierra.png";
//import MembershipModal from "../Membership/MembershipPage";  // Importa el MembershipModal


SwiperCore.use([Navigation]);

export const HeroSwiper = () => {

  const [openMembershipModal, setOpenMembershipModal] = useState(false);  

  const handleOpenMembershipModal = () => {
    setOpenMembershipModal(true);
  };
  
  const handleCloseMembershipModal = () => {
    setOpenMembershipModal(false);
  };
  

  return (
    <section className="section section__hero">
      <Swiper
        //navigation={true}
        //loop={true}
        //centeredSlides={true}
        className="hero-swiper"
      >
       <SwiperSlide>
        <div className="hero__card-container">
          <img className="hero__img" src={banner1} alt="" />
          <div className="hero__card">
          <h3 className="hero__card--title">
            ¡El ahorro que buscas,<br />la calidad que te mereces!
          </h3>
            <p className="hero__card--description"></p>
            {/* Contenedor para los enlaces */}
            <div className="hero__card--links">
              <Link to="/register" className="hero__login">
                Registrarse
              </Link>
              <Link to="/membership" className="hero__login" onClick={handleOpenMembershipModal}>
                Membresía
              </Link>
            </div>
          </div>
        </div>
      </SwiperSlide>
      </Swiper>
    </section>
  );
};
// import React, { useState, useRef, useEffect } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import Modal from "@mui/material/Modal";
// import "./HeroSwiper.scss"; // Tus estilos personalizados

// import banner1 from "../../assets/swiper/Feria-sin-logo.png";
// import banner2 from "../../assets/swiper/Cultivos-sin-logo.png";
// import banner3 from "../../assets/swiper/Acelgas-sin-logo.png";
// import videoThumbnail from "../../assets/swiper/DALL.jpg"; // Imagen de previsualización del video
// const videoUrl = "https://takayapp.cl/TAKAY_EXPLICACION_PAGINA.mp4"; // Enlace al video

// export const HeroSwiper = () => {
//   const heroContainerRef = useRef(null);
//   const [slideHeight, setSlideHeight] = useState(0);
//   const [openModal, setOpenModal] = useState(false);

//   useEffect(() => {
//     // Obtener la altura del Swiper dinámicamente
//     if (heroContainerRef.current) {
//       setSlideHeight(heroContainerRef.current.clientHeight);
//     }
//   }, []);

//   const handleOpenModal = () => {
//     setOpenModal(true);
//   };

//   const handleCloseModal = () => {
//     setOpenModal(false);
//   };

//   return (
//     <section className="section section__hero">
//       <div className="hero__content">
//         {/* Swiper */}
//         <div className="hero__container" ref={heroContainerRef}>
//           <Slider className="hero-swiper">
//             {[banner1, banner2, banner3].map((banner, index) => (
//               <div key={index} className="hero__slide">
//                 <img className="hero__img" src={banner} alt={`Banner ${index + 1}`} />
//               </div>
//             ))}
//           </Slider>
//         </div>

//         {/* Imagen del video (con el tamaño original) */}
//         <div className="video-container" style={{ height: `${slideHeight}px` }} onClick={handleOpenModal}>
//           <img src={videoThumbnail} alt="Video Thumbnail" className="video-thumbnail" />
//         </div>

//         {/* Modal del video */}
//         <Modal open={openModal} onClose={handleCloseModal} className="video-modal">
//           <div className="video-modal-content">
//             <button className="video-close-btn" onClick={handleCloseModal}>✖</button>
//             <video className="video-frame" src={videoUrl} controls autoPlay />
//           </div>
//         </Modal>
//       </div>
//     </section>
//   );
// };




