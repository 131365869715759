import axios from 'axios';
import { API_URL } from '../const/api';

const API = `${API_URL}/products`; // Cambia la URL según tu configuración
export const getProducts = async () => {
  try {
    const response = await axios.get(`${ API }/all`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const getProductsAdmin = async () => {
  try {
    const response = await axios.get(`${ API }/all-admin`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const getPrincipal = async () => {
  try {
    const response = await axios.get(`${ API }/principal`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const getProductsSubgroup = async () => {
  try {
    const response = await axios.get(`${ API }/subgroup`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const uploadFile = async (formData) => {
  try {
    const response = await axios.post(`${ API }/history`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};


export const enabled = async (id, enabled) => {
  try {
    const response = await axios.patch(`${ API }/enable/${id}`, { enabled }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const generateEnabledProductsExcel = async () => {
  try {
    const response = await axios.get(`${ API }/products-enable`, {
      responseType: "arraybuffer",
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};