import React, { useState, useRef } from "react";
import { 
  Box, 
  Button, 
  Typography, 
  Input, 
  Paper, 
  CircularProgress, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions 
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { uploadFile, generateEnabledProductsExcel } from "../../client/apiProducts";
import { Download as DownloadIcon } from "@mui/icons-material"; // Ícono de descarga


export const UploadPriceFilePage = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setDialogOpen(false);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Por favor, selecciona un archivo para subir.");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await uploadFile(formData);
      if (response.message === "Updated successfully") {
        setDialogOpen(true);
        handleClearSelection();
      }
    } catch (error) {
      alert("Hubo un problema al cargar el archivo. Inténtalo de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  const handleClearSelection = () => {
    setFile(null);
    fileInputRef.current.value = "";
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // 📥 Descargar el Excel generado en el backend
  const handleDownloadExcel = async () => {
    try {
      const response = await generateEnabledProductsExcel();
      console.log(response)
      // Crear un Blob con el archivo recibido
      const blob = new Blob([response], { 
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });

      // Crear un enlace para descargar el archivo
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "Base_de_Productos_Habilitados.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el archivo Excel:", error);
      alert("Hubo un problema al descargar el archivo. Inténtalo de nuevo.");
    }
  };

  return (
    <Box sx={{ textAlign: "center", mt: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Subir archivo Excel para cambio de precios
      </Typography>
      
      <Paper elevation={3} sx={{ padding: 3, display: "inline-block", mb: 3, width: "80%" }}>
        <Box sx={{ mb: 2 }}>
          <Input type="file" onChange={handleFileChange} inputRef={fileInputRef} />
        </Box>

        <Box display="flex" justifyContent="center" gap={2} alignItems="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClearSelection}
            disabled={!file || loading}
          >
            Limpiar Selección
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleUpload}
            disabled={!file || loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? "Cargando..." : "Subir y Actualizar Precios"}
          </Button>
        </Box>

        {/* 📥 Botón de descarga del archivo Excel desde la API */}
        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDownloadExcel}
            startIcon={<DownloadIcon />}
          >
            Descargar Base de Productos
          </Button>
        </Box>
      </Paper>

      {/* Botón para regresar a Admin */}
      <Box sx={{ mt: 3, marginBottom: 2 }}>
        <Button
          variant="outlined"
          onClick={() => navigate("/admin")}
          sx={{
            color: "green",
            borderColor: "green",
            "&:hover": {
              borderColor: "darkgreen",
              color: "darkgreen",
            },
          }}
        >
          Volver a Admin Panel
        </Button>
      </Box>

      {/* Diálogo de éxito */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Guardado Exitoso</DialogTitle>
        <DialogContent>
          <Typography>Los precios se han actualizado correctamente.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
