import React, { useState, useContext } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  Button,
  TextField,
  CircularProgress
} from '@mui/material';
import { styled } from "@mui/material/styles";
import { getCoupon } from "../../client/apiCoupons";
import { green, red } from '@mui/material/colors';
import { CartContext } from "../../context/CartContext";

const SummarySection = styled(Card)(({ isMobile }) => ({
  width: isMobile ? "100%" : 400,
  marginLeft: isMobile ? 0 : 20,
  padding: 16,
  position: isMobile ? "relative" : "sticky",
  top: isMobile ? "auto" : 100,
  height: "fit-content",
  backgroundColor: "#fff",
  zIndex: 10,
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  marginTop: isMobile ? 20 : 0,
}));

const OrderSummary = ({
  isMobile,
  totalPrice,
  calculateMembershipSavings,
  formatNumber,
  handleBack,
  prepareForCheckout,
  showPersonalData,
  loading
}) => {
  
  const { discount, setDiscount, setCoupon, coupon, balanceFavor, finalPrice, shippingAmount } = useContext(CartContext); 
  const [couponMessage, setCouponMessage] = useState('');

  // Variable para deshabilitar la compra hasta marzo
  const isDisabled = false; // Puedes cambiar esto en marzo a `false`

  const handleApplyCoupon = async () => {
    try {
      const response = await getCoupon({ coupon });
      if (response && response.enable) {
        setDiscount(response.amount);
        setCouponMessage('Cupón aplicado');
      } else {
        setDiscount(0);
        setCouponMessage('Cupón no válido');
      }
    } catch (error) {
      console.error("Error al aplicar el cupón:", error);
      setDiscount(0);
      setCouponMessage('Cupón no válido');
    }
  };

  return (
    <SummarySection isMobile={isMobile}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={1}>
          <TextField
            label="Código de cupón"
            variant="outlined"
            size="small"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: green[500],
                },
                '&:hover fieldset': {
                  borderColor: green[700],
                },
                '&.Mui-focused fieldset': {
                  borderColor: green[500],
                },
              },
            }}
          />
          <Button
            variant="contained"
            color="success"
            sx={{ marginLeft: 1 }}
            onClick={handleApplyCoupon}
          >
            Aplicar
          </Button>
        </Box>
        
        {couponMessage && (
          <Typography variant="body2" color={discount > 0 ? green[500] : red[500]} mb={1}>
            {couponMessage}
          </Typography>
        )}

        <Divider sx={{ margin: "10px 0" }} />

        <Typography variant="h6" gutterBottom>
          Resumen de Pedido
        </Typography>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="body2">Subtotal:</Typography>
          <Typography variant="body2">${formatNumber(totalPrice())}</Typography>
        </Box>
        
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="body2">Descuento:</Typography>
          <Typography variant="body2" color="error">
            -${formatNumber(discount)}
          </Typography>
        </Box>
         {balanceFavor > 0 && (
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="body2">Saldo a favor:</Typography>
            <Typography variant="body2" color="error">
              -${formatNumber(balanceFavor)}
            </Typography>
          </Box>
        )} 
        
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="body2">Valor de despacho:</Typography>
          <Typography variant="body2">${formatNumber(shippingAmount)}</Typography>
        </Box>
        
        <Divider sx={{ margin: "10px 0" }} />
        
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="h6" sx={{ color: "green", fontWeight: "bold" }}>Ahorro por membresía:</Typography>
          <Typography variant="h6" sx={{ color: "green", fontWeight: "bold" }}>
            -${formatNumber(calculateMembershipSavings())}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="h6"><strong>Total:</strong></Typography>
          <Typography variant="h6"><strong>${formatNumber(finalPrice(shippingAmount))}</strong></Typography>
        </Box>

        {/* Mensaje de restricción de compras hasta marzo */}
        {isDisabled && (
          <Typography variant="body2" color="error" sx={{ marginBottom: 2 }}>
            Las compras estarán disponibles nuevamente en marzo.
          </Typography>
        )}

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="outlined" sx={{ color: "green", borderColor: "green", marginRight: 1 }} onClick={handleBack}>
            Volver
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={prepareForCheckout}
            disabled={isDisabled || loading} // Bloqueo del botón hasta marzo
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : showPersonalData ? (
              "Confirmar compra"
            ) : (
              "Continuar"
            )}
          </Button>
        </Box>
      </CardContent>
    </SummarySection>
  );
};

export default OrderSummary;
