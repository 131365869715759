import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Footer.scss";
import facebook from "../../assets/icon/facebook.svg";
import instagram from "../../assets/icon/instagram.svg";
import linkedin from "../../assets/icon/linkedin.svg";
import plane from "../../assets/icon/plane.svg";
import logoMovil from "../../assets/temp/takay-movil.png";
import { useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import logo from "../../assets/temp/logo-header-takay.png";
import { newsletter } from "../../client/apiNewsletter";

export const Footer = () => {
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const [email, setEmail] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setEmailError("Por favor, ingresa un correo electrónico válido.");
      return;
    }
    try {
      await newsletter({ email });
      setDialogMessage("¡Te has suscrito correctamente al newsletter!");
      setEmail("");
    } catch (error) {
      setDialogMessage(
        error.response?.status === 409
          ? "El email ya está registrado."
          : "Ocurrió un error al suscribirte. Inténtalo nuevamente."
      );
    } finally {
      setDialogOpen(true);
      setEmailError("");
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer__col footer__col--info">
          <div className="col-wrap">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={isMobile ? logoMovil : logo} alt="T'akay Logo" style={{ height: isMobile ? 40 : 60 }} />
            </Link>
            <ul className="footer__rrss">
              <li><a href="https://web.facebook.com/takayapp?_rdc=1&_rdr" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="Facebook" /></a></li>
              <li><a href="https://www.instagram.com/takayapp.cl/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="Instagram" /></a></li>
              <li><a href="https://www.linkedin.com/company/t-akay-chile/?viewAsMember=true" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" /></a></li>
            </ul>
            <a className="tyc" href="https://takayapp.cl/docs/POLITICAS_DE_PRIVACIDAD.pdf" target="_blank" rel="noopener noreferrer">Políticas de privacidad</a>
            <a className="tyc" href="https://takayapp.cl/docs/TERMINOS_Y_CONDICIONES.pdf" target="_blank" rel="noopener noreferrer">Términos y condiciones</a>
            <a className="tyc" onClick={(e) => { e.preventDefault(); navigate("/faqs"); }} style={{ cursor: "pointer" }}>Preguntas Frecuentes</a> 
          </div>
        </div>
        <div className="footer__col footer__col--contact">
          <div className="col-wrap">
            <p className="footer__title">Contacto</p>
            <div className="footer__contact">
              <a href="tel:+56973765050">+569 7376 5050</a>
              <a href="mailto:contacto@takayapp.cl">contacto@takayapp.cl</a>
              <address>Santiago, Chile</address>
            </div>
          </div>
        </div>
        <div className="footer__col footer__col--newsletter">
          <div className="col-wrap">
            <p className="footer__title">Newsletter</p>
            <p className="footer__description">Suscríbete para recibir novedades y ofertas especiales.</p>
            <form onSubmit={handleSubmit}>
              <fieldset>
                <input type="email" placeholder="Ingresa tu correo" value={email} onChange={(e) => setEmail(e.target.value)} required />
                <button type="submit"><img src={plane} alt="Enviar" /></button>
              </fieldset>
              {emailError && <p style={{ color: "red", fontSize: "0.8rem", marginTop: "5px" }}>{emailError}</p>}
            </form>
          </div>
        </div>
      </div>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Newsletter</DialogTitle>
        <DialogContent><p>{dialogMessage}</p></DialogContent>
        <DialogActions><Button onClick={() => setDialogOpen(false)} color="primary">Cerrar</Button></DialogActions>
      </Dialog>
    </footer>
  );
};

export default Footer;
